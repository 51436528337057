<template>
  <Header/>
  <LoginStatus />
  <div class="container is-max-desktop">  
    <div v-if="booking.id != 0 && !isDeleted">
      <h1>Booking #{{ currentRoute.params.id }}</h1>
      <article v-if="isNew" class="message is-primary">
        <div class="message-body">
          <p>Din booking er oprettet.</p>
          <p>Se den i <a :href="['/kalender?selectedDate=']+[booking.start.substring(0, 10)]">kalenderen</a>.</p>
        </div>
      </article>
      <a v-if="!isNew && global.navigation.navigatedFrom == 'Calendar'" :href="['/kalender?selectedDate=']+[booking.start.substring(0, 10)]" class="button is-light">
        <span class="icon">
          <i class="fas fa-arrow-left"></i> 
        </span>
        &nbsp;
        Tilbage
      </a>
      <table class="table is-striped is-fullwidth">
        <tbody>
          <tr>
            <th>Booking-id</th>
            <td>{{ currentRoute.params.id }}</td>
          </tr>
          <tr>
            <th>Booking-status</th>
            <td>
              {{ global.phases[booking.phase].da_dk.short }}
              <p v-if="booking.endOriginal != '0000-00-00 00:00:00'">
                <span class="has-background-danger-light p-1 is-size-7"><em>Bemærk:</em> Afsluttet før tid</span>
              </p>

              <button 
                v-if="
                  global.user.account == booking.createdBy.account && 
                  (booking.phase == 10 || booking.phase == 11) && 
                  booking.endOriginal == '0000-00-00 00:00:00'
                " class="button is-warning is-block is-small" 
                @click="bookingEnd()">
                Afslut booking nu
              </button>
            </td>
          </tr>
          <tr>
            <th>Start</th>
            <td>{{ formatDate(booking.start) }}</td>
          </tr>
          <tr>
            <th>Slut</th>
            <td>{{ formatDate(booking.end) }}</td>
          </tr>
          <tr>
            <th>Køretøj</th>
            <td>
              {{ booking.vehicle.name }} ({{ booking.vehicle.brand }} {{ booking.vehicle.model }})
            </td>
          </tr>
          <tr v-if="global.user.account == booking.createdBy.account && booking.phase <= 11" class="distance">
            <td colspan="2">
              <p class="label">Angiv begge km-stande <em>senest</em> ved aflevering:</p>
              <div class="columns">
                <div class="control column">
                  <label for="kmStandStart" class="sr-only">Km-stand ved afhentning</label>
                  <div class="field columns is-mobile">
                    <input class="input column is-two-third" ref="kmStandStart" id="kmStandStart" type="number" step="1" placeholder="Km-stand ved afhentning" :value="booking.kmStandStart == 0 ? booking.vehicleLastKmStand : booking.kmStandStart">
                    <button ref="kmStandStartBtn" class="button is-info column is-one-third" @click="saveKmStand('kmStandStart')">{{ buttons.kmStandStart }}</button>
                  </div>
                  <article v-if="messagesOnSaves.kmStandStart.visible" class="message is-primary">
                    <div class="message-body">
                      Km-stand er gemt. Du kan ændre begge frem til, at din booking slutter.
                    </div>
                  </article>
                  <article v-if="booking.kmStandStart == 0" class="message is-warning">
                    <div class="message-body">
                      Forrige kører har angivet <strong>{{ booking.vehicleLastKmStand }}</strong> km som km-stand ved sin aflevering.
                    </div>
                  </article>
                </div>
                <div class="control column">
                  <label for="kmStandEnd" class="sr-only">Km-stand ved aflevering</label>
                  <div class="field columns is-mobile">
                    <input class="input column is-two-third" ref="kmStandEnd" id="kmStandEnd" type="number" step="1" placeholder="Km-stand ved aflevering" :value="booking.kmStandEnd == 0 ? '' : booking.kmStandEnd">
                    <button ref="kmStandEndBtn" class="button is-info column is-one-third" @click="saveKmStand('kmStandEnd')">{{ buttons.kmStandEnd }}</button>
                  </div>
                  <article v-if="messagesOnSaves.kmStandEnd.visible" class="message is-primary">
                    <div class="message-body">
                      Km-stand er gemt. Du kan ændre begge frem til, at din booking slutter.
                    </div>
                  </article>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="booking.phase == 100">
            <th>Kørt</th>
            <td>
              {{ booking.kmStandEnd - booking.kmStandStart }} km <br><br>
              Start: {{ booking.kmStandStart }} km<br>
              Slut: {{ booking.kmStandEnd }} km
            </td>
          </tr>
          <tr v-if="booking.phase == 100">
            <th>Pris</th>
            <td>
              {{ parseInt(booking.timePriceV2) + parseInt(booking.distancePriceV2) }} kr.
            </td>
          </tr>
          <tr>
            <th>Booket af</th>
            <td>{{ booking.createdBy.displayName }} ({{ booking.account.name }})</td>
          </tr>
          <tr>
            <th>Booket</th>
            <td>{{ formatDate(booking.created) }}</td>
          </tr>
          <tr v-if="booking.comment || booking.isAdminBooking">
            <th>Kommentar</th>
            <td>
              {{ booking.isAdminBooking ? 'Administrator-booking. ' : '' }}
              {{ booking.comment }}
            </td>
          </tr>
        </tbody>
      </table>
      <button v-if="global.user.account == booking.createdBy.account && booking.phase == 20" class="button is-danger" @click="bookingDelete()">
        Slet booking
      </button>
    </div>

    <div v-if="isDeleted">
      <h1>Booking #{{ currentRoute.params.id }} er slettet</h1>
      <article class="message is-primary">
        <div class="message-body">
          <p>Bookingen er nu slettet.</p>
          <p>Gå til <a href="/kalender">kalenderen</a> for at booke en ny.</p>
        </div>
      </article>
    </div>
  </div>
  

  <Footer/>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import LoginStatus from '@/components/LoginStatus.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  setup() {
        const { global } = useComposition();
        return {
            global
        }
    },
  data() {
    return {
      booking: { 
        id: 0,
        vehicleLastKmStand: 0

      },
      isNew: false,
      isDeleted: false,
      buttons: {
        kmStandStart: 'Gem',
        kmStandEnd: 'Gem',
      },
      messagesOnSaves: {
        kmStandStart: {
          visible: false
        },
        kmStandEnd: {
          visible: false
        },
      }
    }
  },
  components: {
    Header,
    LoginStatus,
    Footer
  },
  created() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.isNew = urlParams.get('new') == '1' ? true : false;
      this.bookingLoad();
  },
  methods: {
    bookingLoad() {
      axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?id=' + this.$route.params.id)
        .then(response => {
            this.booking = response.data;
            document.title = 'Booking nr. #' + this.booking.id;
            //document.querySelector('meta[name="description"]').setAttribute("content", this.content.metaDescription);
            if(this.booking.kmStandStart == 0) {
              this.vehicleGetLastKmStand(this.booking.vehicle.id);
            }
            
        })
        .catch(function (error) {
          
        });
    },
    bookingDelete() {
      if(
        confirm("Vil du slette denne booking?")
      ) {
        axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?mode=delete&id=' + this.booking.id)
          .then(response => {
            this.isDeleted = true;
          })
      }      
    },
    bookingEnd() {
      if(
        confirm("Vil du stoppe denne booking? Så kan andre  booke køretøjet.")
      ) {
        axios.get(process.env.VUE_APP_API_ROOT + '/booking.php?mode=endBooking&id=' + this.booking.id + '&endOriginal=' + this.booking.end)
          .then(response => {
            this.bookingLoad();
          })
      }      
    },
    saveKmStand(field) {
      var km = 0;
      switch(field) {
        case 'kmStandStart':
          km = this.$refs.kmStandStart.value, field;
          this.booking.kmStandStart = km;
          this.buttons.kmStandStart = 'Gemmer...';
          break;

        case 'kmStandEnd':
          km = this.$refs.kmStandEnd.value, field;
          this.booking.kmStandEnd = km;
          this.buttons.kmStandEnd = 'Gemmer...';
          break;
      }
      
      axios.get(process.env.VUE_APP_API_ROOT + '/distance.php?mode=save&km=' + km + '&field=' + field + '&booking=' + this.booking.id)
        .then(response => {
          setTimeout(function(scope) { 
            switch(field) {
              case 'kmStandStart':
                scope.buttons.kmStandStart = 'Gem';
                scope.messagesOnSaves.kmStandStart.visible = true;

                setTimeout(function(scopeInner) { 
                  scopeInner.messagesOnSaves.kmStandStart.visible = false;
                }, 3000, scope);
                break;

              case 'kmStandEnd':
                scope.buttons.kmStandEnd = 'Gem';
                scope.messagesOnSaves.kmStandEnd.visible = true;

                setTimeout(function(scopeInner) { 
                  scopeInner.messagesOnSaves.kmStandEnd.visible = false;
                }, 3000, scope);
                break;
            }
            scope.bookingLoad();
          }, 500, this);
        })
    },
    vehicleGetLastKmStand(vehicleId) {
      axios.get(process.env.VUE_APP_API_ROOT + '/distance.php?vehicle=' + vehicleId)
        .then(response => {
          this.booking.vehicleLastKmStand = response.data.lastKmStandEnd;
        })
    },
    formatDate(date) {
      return date.substring(8, 10) + '.' + parseInt(date.substring(5, 7)) + '.' + date.substring(0, 4) + ' kl. ' + date.substring(11, 16);
    }
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})
</script>